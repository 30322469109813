import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSiteMetadata } from '../hooks';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import Footer from '../components/Footer/Footer';

export const encode = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

// TODO clamp text na titulce
// TODO formular

const Kontakt = ({ data }) => {
  const [success, setSuccess] = useState(false);

  const {
    title: siteTitle,
    subtitle: siteSubtitle
  } = useSiteMetadata();
  const pageTitle = 'Kontakt';
  const metaDescription = 'Mga Jan Hlavatý kontakt';
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...data,
      }),
    })
      .then(() => {
        reset();
        setSuccess(true);
      })
      .catch((error) => alert(error));
  };

  return (
        <Layout title={`${pageTitle} - ${siteTitle}`} description={metaDescription}>
            <Sidebar />
            <Page title={pageTitle}>
                <p>Pokud chcete něco sdělit autorovi textů, využijte kontaktní formulář (níže). Děkujeme</p>
                <div
                    dangerouslySetInnerHTML={{
                      __html:
                            '<form name="contact" netlify netlify-honeypot="bot-field" hidden><input type="text" name="email" /><textarea name="message"></textarea></form>',
                    }}
                />
                {success ? <div>
                    Děkuji za vaší zprávu!
                </div> : <div className="form-style-2">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="field2"><span>Email <span className="required">*</span></span><input type="text"
                                                                                                             className="input-field"
                                                                                                             {...register('email')}

                        /></label>

                        <label htmlFor="field5"><span>Zpráva <span className="required">*</span></span>
                            <textarea
                                {...register('message', { required: true })}
                                className="textarea-field" /></label>

                        <label><span> </span><input type="submit" value="Odeslat" /></label>
                        {errors.exampleRequired && <span>This field is required</span>}
                    </form>
                </div>}
            </Page>
            <Footer showUp={false} />
        </Layout>
  );
};

export default Kontakt;
